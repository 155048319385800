
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo, DicList, MaintenanceForm } from '@/types/common'
import { DataList } from '@/types/rareTrees'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'
import maintenanceConfigDialog from '@/components/maintenanceConfigDialog/Index.vue'
import seedingTypeConfigDialog from '@/components/seedingTypeConfigDialog/Index.vue'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'RareTreesList',
  components: { MaintenanceDialog, maintenanceConfigDialog, seedingTypeConfigDialog },
  filters: {
    filtersState (value: string) {
      return value === '1' ? '倾斜' : value === '0' ? '正常' : ''
    }
  }
})
export default class extends Vue {
  private showDialog = false
  private showDevice = false
  private searchInfo = {
    seedlingCode: '',
    plantName: '',
    projectName: '',
    protectionLevel: '',
    isFamous: 1,
    runState: ''
  }

  private deviceInfo = {
    plantName: '',
    seedlingCode: '',
    deviceId: '',
    seedlingId: ''
  }

  private devideRules = {
    deviceId: [
      { required: true, message: '请选择设备串号', trigger: 'change' }
    ]
  }

  private deviceList = []

  private protectionLevelList: Array<DicInfo> = []
  private currentSeedlingId = ''

  private page = 1
  private size = 10
  private total = 0
  private tableData: DataList[] =[]
  private loading = false
  private maintainTypeShow = false
  private seedingTypeShow = false

  created () {
    this.$forceUpdate()
    this.getData()
    this.getProtectionLevel()
  }

  getDevice (projectId: string) {
    this.$axios.get(this.$apis.seedling.selectUnBindDumps, {
      projectId: projectId
    }).then(res => {
      this.deviceList = res || []
    })
  }

  // 添加养护记录
  addMaintenance (id: string) {
    this.currentSeedlingId = id
    this.showDialog = true
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.seedling.exportSeedlingData, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  // 添加养护类型
  setMaintainType () {
    this.maintainTypeShow = true
  }

  // 添加苗木类别
  setSeedlingType () {
    this.seedingTypeShow = true
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 获取保护等级列表
  getProtectionLevel () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, {
      dicType: 'protectionLevel'
    }).then((res) => {
      this.protectionLevelList = res.protectionLevel || []
    })
  }

  // 获取表格数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<DataList>}>(this.$apis.seedling.selectSeedlingByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增苗木
  addTrees () {
    this.$router.push('/rareTreesBase/list/add')
  }

  editTrees (seedlingId: string) {
    this.$router.push({
      path: `/rareTreesBase/list/edit${seedlingId}`
    })
  }

  goDetail (seedlingId: string) {
    this.$router.push({
      path: `/rareTreesBase/list/detail${seedlingId}`
    })
  }

  deleteTrees (seedlingId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedling.deleteSeedling, {
        seedlingId
      }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    })
  }

  closeDialog () {
    this.showDialog = false
  }

  saveData (formData: MaintenanceForm) {
    this.$axios.post(this.$apis.record.insertMaintainRecord, {
      objectId: this.currentSeedlingId,
      ...formData
    }).then(() => {
      this.$message.success('添加成功')
      this.searchData()
    }).finally(() => {
      this.showDialog = false
    }).catch(() => {
      (this.$refs.maintenance as MaintenanceDialog).saveDisabledShow()
    })
  }

  // 取消绑定
  cancelBind (seedlingId: string) {
    this.$confirm('确认解除绑定设备?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedling.unBindFamousSeedling, {
        seedlingId
      }).then(() => {
        this.$message.success('解绑成功')
        this.searchData()
      })
    })
  }

  // 绑定倾斜设备
  bindDevice (row: DataList) {
    // this.$axios.post(this.$apis.seedling.bindFamousSeedling)
    this.deviceInfo = {
      plantName: row.plantName,
      seedlingCode: row.seedlingCode,
      deviceId: '',
      seedlingId: row.seedlingId
    }
    this.getDevice(row.projectId)
    this.showDevice = true
  }

  onSubmit () {
    (this.$refs.deviceForm as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          seedlingId: this.deviceInfo.seedlingId,
          deviceId: this.deviceInfo.deviceId
        }
        this.$axios.post(this.$apis.seedling.bindFamousSeedling, info)
          .then(() => {
            (this.$refs.deviceForm as ElForm).resetFields()
            this.showDevice = false
            this.$message.success('保存成功')
            this.searchData()
          })
      }
    })
  }
}
